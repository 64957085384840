// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#FF2E2E',
        secondary: '#0c0c0c',
        tertiary: '#E52929',
        accent: '#F0DEDE',
      },
      dark: {
        primary: '#50778D',
        secondary: '#0B1C3D',
        accent: '#204165',
      },
    },
  },
})
