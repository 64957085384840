// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/views/home/Index.vue'),
        },
        {
          path: 'shows',
          name: 'Shows',
          component: () => import('@/views/shows/Index.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'team',
          name: 'Team',
          component: () => import('@/views/team/Index.vue'),
          meta: { src: require('@/assets/pro.jpg') },
        },
        {
          path: 'news',
          name: 'News',
          component: () => import('@/views/news/Index.vue'),
          meta: { src: require('@/assets/BuzNews.jpg') },
        },
        {
          path: 'preise',
          name: 'Preise',
          component: () => import('@/views/preise/Index.vue'),
          meta: { src: require('@/assets/BuzNews.jpg') },
        },
        {
          path: 'impressum',
          name: 'Impressum',
          component: () => import('@/views/impressum/Index.vue'),
        },
        {
          path: 'agb',
          name: 'Agb',
          component: () => import('@/views/agb/Index.vue'),
        },
      ],
    },

  ],
})

export default router
